/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMutation } from '@tanstack/react-query';
import {
    apiDeleteScheduledNotifications,
    apiPostScheduleNotifications,
} from 'api';
import {
    type CustomMutationResult,
    type ScheduleNotificationDto,
} from 'api/types';

export const useScheduleNotificationMutation = (): CustomMutationResult<
    unknown,
    ScheduleNotificationDto
> => {
    return useMutation({
        mutationFn: async (data: ScheduleNotificationDto) => {
            const response = await apiPostScheduleNotifications(data);
            return response.data;
        },
    });
};

export const useDeleteNotificationSchedulesMutation = (): CustomMutationResult<
    unknown,
    void
> => {
    return useMutation({
        mutationFn: async () => {
            const response = await apiDeleteScheduledNotifications();
            return response.data;
        },
    });
};
