/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
    DeleteOutlined,
    DollarCircleTwoTone,
    DownOutlined,
    DownloadOutlined,
    FilterOutlined,
    PlusOutlined,
    SearchOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import {
    Button,
    Card,
    Checkbox,
    Col,
    Dropdown,
    Row,
    Select,
    Space,
    Spin,
    Statistic,
    Switch,
    Table,
    Tooltip,
    Typography,
} from 'antd';
import { Input } from 'antd';
import {
    useAllSwapsQuery,
    useAllUsersStatsQuery,
    useGetAllStatsQuery,
    useIsUpgradedQuery,
} from 'api';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import type { $MixedElement } from 'types';
import { defaultUserData } from 'utils';
import {
    DEFAULT_CHECKED,
    ETH_ADDRESS,
    FEE_PCT,
    GRAPH_ETH_ADDRESS,
    type IUser,
    PAGE_SIZE,
    type UserBalances,
    UserColumns,
    addressToToken,
    formatUnits,
    fromDollar,
    toDollar,
    tokenAddressToDecimal,
} from 'utils';

type Filter = {
    column: keyof IUser;
    value: string | number;
    operator: 'eq' | 'gt' | 'lt' | 'neq';
};

export const AllStats = ({
    tokenPrices,
    balancesUSD,
    balances,
}: {
    tokenPrices: Record<string, number> | null;
    balancesUSD: UserBalances | null;
    balances: UserBalances | null;
}): $MixedElement => {
    //  _______ _________ _______ _________ _______
    //  (  ____ \\__   __/(  ___  )\__   __/(  ____ \
    //  | (    \/   ) (   | (   ) |   ) (   | (    \/
    //  | (_____    | |   | (___) |   | |   | (__
    //  (_____  )   | |   |  ___  |   | |   |  __)
    //        ) |   | |   | (   ) |   | |   | (
    //  /\____) |   | |   | )   ( |   | |   | (____/\
    //  \_______)   )_(   |/     \|   )_(   (_______/

    // Group columns for better organization
    const columnGroups = [
        {
            title: 'User Info',
            children: ['username', 'email', 'key', 'referrer', 'countryCode'],
        },
        {
            title: 'Portfolio',
            children: [
                'totalPortfolio',
                'idlePortfolio',
                'earnPositions',
                'deposit',
            ],
        },
        {
            title: 'Revenue',
            children: ['swapRevenue', 'realizedGain', 'referralGain'],
        },
        {
            title: 'Activity',
            children: [
                'txCount',
                'activeDays',
                'activeWeeks',
                'activeMonths',
                'paymentLinks',
                'swap',
                'transfer',
                'earn',
            ],
        },
        {
            title: 'Other',
            children: [
                'hasBackup',
                'campaignStatus',
                'refCount',
                'creationDate',
                'upgraded',
            ],
        },
    ];
    const [checkedList, setCheckedList] =
        useState<Array<keyof IUser>>(DEFAULT_CHECKED);
    const [config, setConfig] = useState({
        sortBy: 'totalPortfolio' as keyof IUser,
        filters: [] as Array<Filter>,
        filterCondition: 'AND' as 'OR' | 'AND',
    });
    const [sortBy, setSortBy] = useState<keyof IUser>('totalPortfolio');
    const [filters, setFilters] = useState<Array<Filter>>([]);
    const [filterCondition, setFilterCondition] = useState<'OR' | 'AND'>('AND');
    const [totalRows, setTotalRows] = useState(0);
    const [totalIdleTvl, setTotalIdleTvl] = useState(0);
    const [totalInvested, setTotalInvested] = useState(0);
    const [totalRealizedGain, setTotalRealizedGain] = useState(0);
    const [totalDeposited, setTotalDeposited] = useState(0);
    const [totalSwapRevenue, setTotalSwapRevenue] = useState(0);
    // _______  _        _______  _______  _______
    // |\     /|(  ____ \( \      (  ____ )(  ____ \(  ____ )
    // | )   ( || (    \/| (      | (    )|| (    \/| (    )|
    // | (___) || (__    | |      | (____)|| (__    | (____)|
    // |  ___  ||  __)   | |      |  _____)|  __)   |     __)
    // | (   ) || (      | |      | (      | (      | (\ (
    // | )   ( || (____/\| (____/\| )      | (____/\| ) \ \__
    // |/     \|(_______/(_______/|/       (_______/|/   \__/
    const applyButtonRef = useRef<HTMLButtonElement | null>(null);

    const getExportableData = (data: Array<IUser> | null): Array<object> => {
        if (!data) {
            return [];
        }

        return data.map((user) => {
            const result: { [key: string]: string | number | JSX.Element } = {};
            for (const key of checkedList) {
                result[key == 'key' ? 'address' : key] =
                    user[key as keyof IUser];
                if (key === 'earnPositions' || key === 'realizedGain') {
                    result[key] = toDollar(
                        parseFloat((result[key] as JSX.Element).key ?? '0'),
                    );
                } else if (key === 'txCount') {
                    result[key] = Number((result[key] as JSX.Element).key);
                }
            }
            return result;
        });
    };

    //  _______           _______  _______ _________ _______  _______
    //  (  ___  )|\     /|(  ____ \(  ____ )\__   __/(  ____ \(  ____ \
    //  | (   ) || )   ( || (    \/| (    )|   ) (   | (    \/| (    \/
    //  | |   | || |   | || (__    | (____)|   | |   | (__    | (_____
    //  | |   | || |   | ||  __)   |     __)   | |   |  __)   (_____  )
    //  | | /\| || |   | || (      | (\ (      | |   | (            ) |
    //  | (_\ \ || (___) || (____/\| ) \ \_____) (___| (____/\/\____) |
    //  (____\/_)(_______)(_______/|/   \__/\_______/(_______/\_______)

    const { data: stats } = useAllUsersStatsQuery();
    const {
        data: { inAppSwaps: swaps },
    } = useAllSwapsQuery();
    const { data: statsDb } = useGetAllStatsQuery();
    const { data: isUpgraded, refetch: refetchIsUpgraded } = useIsUpgradedQuery(
        stats.claveAccounts.map((account) => account.id),
    );

    useEffect(() => {
        if (stats.claveAccounts.length > 15000) {
            refetchIsUpgraded();
        }
    }, [stats]);

    //  _______  _______  _______  _______ _________ _______  _______ __________________ _______  _        _______
    //  (       )(  ____ \(       )(  ___  )\__   __// ___   )(  ___  )\__   __/\__   __/(  ___  )( (    /|(  ____ \
    //  | () () || (    \/| () () || (   ) |   ) (   \/   )  || (   ) |   ) (      ) (   | (   ) ||  \  ( || (    \/
    //  | || || || (__    | || || || |   | |   | |       /   )| (___) |   | |      | |   | |   | ||   \ | || (_____
    //  | |(_)| ||  __)   | |(_)| || |   | |   | |      /   / |  ___  |   | |      | |   | |   | || (\ \) |(_____  )
    //  | |   | || (      | |   | || |   | |   | |     /   /  | (   ) |   | |      | |   | |   | || | \   |      ) |
    //  | )   ( || (____/\| )   ( || (___) |___) (___ /   (_/\| )   ( |   | |   ___) (___| (___) || )  \  |/\____) |
    //  |/     \|(_______/|/     \|(_______)\_______/(_______/|/     \|   )_(   \_______/(_______)|/    )_)\_______)

    const revenueGenerated = useMemo(() => {
        const revenueGenerated: Record<string, number> = {};
        if (!swaps || !tokenPrices) {
            return null;
        }
        swaps.forEach((swap) => {
            const tokenAddress = swap.tokenOut.toLowerCase();
            const token = addressToToken[tokenAddress];
            if (!token) {
                return;
            }
            const decimals = token.decimals;
            const tokenPrice = tokenPrices[tokenAddress];
            if (!tokenPrice) {
                return;
            }
            const amountOut = formatUnits(swap.amountOut, decimals);
            const fee = amountOut * FEE_PCT;
            const usdValue = fee * tokenPrice;
            revenueGenerated[swap.account.id] =
                (revenueGenerated[swap.account.id] ?? 0) + usdValue;
        });

        return revenueGenerated;
    }, [swaps, tokenPrices]);

    const columns = useMemo(() => {
        return UserColumns.map((column) => {
            if (column.title === config.sortBy) {
                return {
                    ...column,
                    hidden: !checkedList.includes(column.key as keyof IUser),
                };
            } else {
                return {
                    ...column,
                    hidden: !checkedList.includes(column.key as keyof IUser),
                };
            }
        });
    }, [config, checkedList]);

    const tableData = useMemo(() => {
        if (
            !stats ||
            !balancesUSD ||
            !revenueGenerated ||
            !tokenPrices ||
            stats.claveAccounts.length < 10100
        ) {
            return null;
        }

        const tableData: Array<IUser> = stats.claveAccounts.map((account) => {
            const address: string = account.id;
            const revenue = revenueGenerated[address] ?? 0;
            const upgraded = isUpgraded[address]
                ? 'true'
                : isUpgraded[address] === false
                ? 'false'
                : 'N/A';
            const statDb = statsDb[address] ?? defaultUserData;
            const countryCode = statDb.countryCode ?? 'N/A';
            const creationDate = statDb.creationDate.slice(4);
            const campaignStatus =
                statDb.campaigns[0]?.status == 'done'
                    ? 'done'
                    : statDb.referrerAddress != null
                    ? 'pending'
                    : 'N/A';

            const totalBalance = Object.entries(
                balancesUSD[account.id] ?? {},
            ).reduce((acc, [, balance]) => acc + balance, 0);

            const activeDays = account.activeDays.length;
            const activeWeeks = account.activeWeeks.length;
            const activeMonths = account.activeMonths.length;

            const refCount = statDb.referredWallets.length ?? 0;

            let deposited = 0;

            const depositsForAccount = statDb.depositData ?? {};
            const depositData = Object.entries(depositsForAccount).reduce(
                (acc, [address, amount]) => {
                    const tokenAddress =
                        address === GRAPH_ETH_ADDRESS ? ETH_ADDRESS : address;
                    const token = addressToToken[tokenAddress];
                    const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                    const decimal = tokenAddressToDecimal[tokenAddress] ?? 18;
                    const usdValue = formatUnits(amount, decimal) * tokenPrice;
                    deposited += usdValue;
                    acc.push({
                        token: token?.symbol ?? tokenAddress,
                        deposited: usdValue,
                    });
                    return acc;
                },
                [] as Array<{ token: string; deposited: number }>,
            );

            let invested = 0;

            const earnPositions = account.earnPositions.reduce(
                (acc, position) => {
                    let tokenAddress = position.token.toLowerCase();
                    if (tokenAddress === GRAPH_ETH_ADDRESS)
                        tokenAddress = ETH_ADDRESS;
                    const token = addressToToken[tokenAddress];
                    const amount = formatUnits(
                        position.invested,
                        token?.decimals ?? 18,
                    );
                    const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                    const usdValue = amount * tokenPrice;
                    invested += usdValue;
                    acc.push({
                        protocol: `${position.protocol}${token?.symbol}`,
                        invested: usdValue,
                    });
                    return acc;
                },
                [] as Array<{ protocol: string; invested: number }>,
            );

            let realizedGain = 0;

            const earnGains = account.earnPositions.reduce((acc, position) => {
                let tokenAddress = position.token.toLowerCase();
                if (tokenAddress === GRAPH_ETH_ADDRESS)
                    tokenAddress = ETH_ADDRESS;
                const token = addressToToken[tokenAddress];
                const gain =
                    position.protocol === 'ZeroLend'
                        ? position.normalGain + position.compoundGain
                        : position.normalGain;
                const amount = formatUnits(gain, token?.decimals ?? 18);
                const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                const usdValue = amount * tokenPrice;
                realizedGain += usdValue;
                acc.push({
                    protocol: `${position.protocol}${token?.symbol}`,
                    gained: usdValue,
                });
                return acc;
            }, [] as Array<{ protocol: string; gained: number }>);

            const refFeeGains = account.referralFees.reduce((acc, fee) => {
                let tokenAddress = fee.erc20.toLowerCase();
                if (tokenAddress === GRAPH_ETH_ADDRESS)
                    tokenAddress = ETH_ADDRESS;
                const token = addressToToken[tokenAddress];
                const amount = formatUnits(fee.amount, token?.decimals ?? 18);
                const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                const usdValue = amount * tokenPrice;
                return acc + usdValue;
            }, 0);

            const cashbackGains = account.cashbacks.reduce((acc, cashback) => {
                let tokenAddress = cashback.erc20.toLowerCase();
                if (tokenAddress === GRAPH_ETH_ADDRESS)
                    tokenAddress = ETH_ADDRESS;
                const token = addressToToken[tokenAddress];
                const amount = formatUnits(
                    cashback.amount,
                    token?.decimals ?? 18,
                );
                const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                const usdValue = amount * tokenPrice;
                return acc + usdValue;
            }, 0);

            const username = statDb.username ?? 'N/A';
            const email = statDb.email ?? 'N/A';

            const dist = statDb.txDistribution;
            const earn = dist?.invest || 0;
            const paymentLinks = dist?.peanut || 0;
            const swap = dist?.swap || 0;
            const transfer = dist?.transfer || 0;

            return {
                username,
                key: address,
                countryCode,
                email,
                referrer:
                    statsDb[statDb.referrerAddress ?? '']?.username ?? 'N/A',
                totalPortfolio: toDollar(totalBalance + invested),
                idlePortfolio: toDollar(totalBalance),
                swapRevenue: toDollar(revenue),
                txCount: (
                    <Tooltip
                        title={
                            <>
                                <p>payment links: {paymentLinks}</p>
                                <p>swap: {swap}</p>
                                <p>transfer: {transfer}</p>
                                <p>earn: {earn}</p>
                            </>
                        }
                        key={account.txCount}
                    >
                        <span>{account.txCount}</span>
                    </Tooltip>
                ),
                earnPositions: (
                    <Tooltip
                        title={
                            <>
                                {earnPositions.map(
                                    (position) =>
                                        position.invested > 0.1 && (
                                            <p key={position.invested}>
                                                {position.protocol}:{' '}
                                                {toDollar(position.invested)}
                                            </p>
                                        ),
                                )}
                            </>
                        }
                        key={invested}
                    >
                        <span>{toDollar(invested)}</span>
                    </Tooltip>
                ),
                hasBackup: account.hasRecovery.toString(),
                campaignStatus,
                creationDate,
                activeDays,
                activeWeeks,
                activeMonths,
                realizedGain: (
                    <Tooltip
                        title={
                            <>
                                {earnGains.map(
                                    (gain) =>
                                        gain.gained > 0.1 && (
                                            <p key={gain.gained}>
                                                {gain.protocol}:{' '}
                                                {toDollar(gain.gained)}
                                            </p>
                                        ),
                                )}
                            </>
                        }
                        key={realizedGain}
                    >
                        <span>{toDollar(realizedGain)}</span>
                    </Tooltip>
                ),
                deposit: (
                    <Tooltip
                        title={
                            <>
                                {depositData.map((deposit) => (
                                    <p key={deposit.deposited}>
                                        {deposit.token}:{' '}
                                        {toDollar(deposit.deposited)}
                                    </p>
                                ))}
                            </>
                        }
                        key={deposited}
                    >
                        <span>{toDollar(deposited)}</span>
                    </Tooltip>
                ),
                referralGain: (
                    <Tooltip
                        title={
                            <>
                                <p>referral fee: {toDollar(refFeeGains)}</p>
                                <p>cashback: {toDollar(cashbackGains)}</p>
                            </>
                        }
                        key={refFeeGains + cashbackGains}
                    >
                        {toDollar(refFeeGains + cashbackGains)}
                    </Tooltip>
                ),
                refCount,
                earn,
                paymentLinks,
                swap,
                transfer,
                upgraded,
            };
        });

        const filteredTableData = tableData.filter((user) => {
            const method = config.filterCondition === 'OR' ? 'some' : 'every';

            return filters[method]((filter) => {
                const value = user[filter.column];
                switch (filter.operator) {
                    case 'eq':
                        switch (filter.column) {
                            case 'txCount':
                            case 'earnPositions':
                            case 'realizedGain':
                            case 'deposit':
                            case 'referralGain': {
                                return (
                                    (value as JSX.Element).key === filter.value
                                );
                            }
                            case 'totalPortfolio':
                            case 'idlePortfolio':
                            case 'swapRevenue': {
                                return (
                                    fromDollar(value as string) ===
                                    Number(filter.value)
                                );
                            }
                            case 'hasBackup':
                            case 'upgraded': {
                                return Boolean(value) === Boolean(filter.value);
                            }
                            case 'creationDate': {
                                return (
                                    new Date(value as string).getTime() ===
                                    new Date(filter.value as string).getTime()
                                );
                            }
                            default:
                                return value === filter.value;
                        }
                    case 'gt':
                        switch (filter.column) {
                            case 'txCount':
                            case 'earnPositions':
                            case 'realizedGain':
                            case 'deposit':
                            case 'referralGain': {
                                return (
                                    Number((value as JSX.Element).key) >
                                    Number(filter.value)
                                );
                            }
                            case 'totalPortfolio':
                            case 'idlePortfolio':
                            case 'swapRevenue': {
                                return (
                                    fromDollar(value as string) >
                                    Number(filter.value)
                                );
                            }
                            case 'creationDate': {
                                return (
                                    new Date(value as string).getTime() >
                                    new Date(filter.value as string).getTime()
                                );
                            }
                            default:
                                return Number(value) > Number(filter.value);
                        }
                    case 'lt':
                        switch (filter.column) {
                            case 'txCount':
                            case 'earnPositions':
                            case 'realizedGain':
                            case 'deposit':
                            case 'referralGain': {
                                return (
                                    Number((value as JSX.Element).key) <
                                    Number(filter.value)
                                );
                            }
                            case 'totalPortfolio':
                            case 'idlePortfolio':
                            case 'swapRevenue': {
                                return (
                                    fromDollar(value as string) <
                                    Number(filter.value)
                                );
                            }
                            case 'creationDate': {
                                return (
                                    new Date(value as string).getTime() <
                                    new Date(filter.value as string).getTime()
                                );
                            }
                            default:
                                return Number(value) < Number(filter.value);
                        }
                    case 'neq':
                        switch (filter.column) {
                            case 'txCount':
                            case 'earnPositions':
                            case 'realizedGain':
                            case 'deposit':
                            case 'referralGain': {
                                return (
                                    (value as JSX.Element).key !== filter.value
                                );
                            }
                            case 'totalPortfolio':
                            case 'idlePortfolio':
                            case 'swapRevenue': {
                                return (
                                    fromDollar(value as string) !==
                                    Number(filter.value)
                                );
                            }
                            case 'hasBackup':
                            case 'upgraded': {
                                return Boolean(value) !== Boolean(filter.value);
                            }
                            case 'creationDate': {
                                return (
                                    new Date(value as string).getTime() !==
                                    new Date(filter.value as string).getTime()
                                );
                            }
                            default:
                                return value !== filter.value;
                        }
                    default:
                        return true;
                }
            });
        });

        let totalIdleTvlAcc = 0;
        let totalInvestedAcc = 0;
        let totalRealizedGainAcc = 0;
        let totalDeposited = 0;
        let totalSwapRevenueAcc = 0;

        filteredTableData.forEach((user) => {
            totalInvestedAcc += Number((user.earnPositions as JSX.Element).key);
            totalRealizedGainAcc += Number(
                (user.realizedGain as JSX.Element).key,
            );
            totalDeposited += Number((user.deposit as JSX.Element).key);
            totalSwapRevenueAcc += fromDollar(user.swapRevenue as string);
            totalIdleTvlAcc += fromDollar(user.idlePortfolio as string);
        });

        setTotalIdleTvl(totalIdleTvlAcc);
        setTotalInvested(totalInvestedAcc);
        setTotalRealizedGain(totalRealizedGainAcc);
        setTotalDeposited(totalDeposited);
        setTotalSwapRevenue(totalSwapRevenueAcc);

        setTotalRows(filteredTableData.length);

        const sortedTableData = filteredTableData.sort((a, b) => {
            switch (config.sortBy) {
                case 'swapRevenue':
                case 'idlePortfolio':
                case 'totalPortfolio':
                    return (
                        fromDollar(b[config.sortBy] as string) -
                        fromDollar(a[config.sortBy] as string)
                    );
                case 'txCount':
                case 'earnPositions':
                case 'realizedGain':
                case 'deposit':
                case 'referralGain':
                    return (
                        Number((b[config.sortBy] as JSX.Element).key) -
                        Number((a[config.sortBy] as JSX.Element).key)
                    );
                case 'activeDays':
                case 'activeWeeks':
                case 'activeMonths':
                case 'refCount':
                case 'earn':
                case 'paymentLinks':
                case 'swap':
                case 'transfer':
                    return Number(b[config.sortBy]) - Number(a[config.sortBy]);
                case 'creationDate':
                    return (
                        new Date(b[config.sortBy]).getTime() -
                        new Date(a[config.sortBy]).getTime()
                    );
                case 'key':
                case 'username':
                case 'email':
                    return a[config.sortBy].localeCompare(b[config.sortBy]);
                default:
                    return 0;
            }
        });

        return sortedTableData;
    }, [stats, isUpgraded, config, revenueGenerated, statsDb, filterCondition]);

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent): void => {
            if (e.key === 'Enter') {
                applyButtonRef.current?.click();
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, []);

    //  _______  _______  _______  _______  _______  _        _______  _       _________
    //  (  ____ \(  ___  )(       )(  ____ )(  ___  )( (    /|(  ____ \( (    /|\__   __/
    //  | (    \/| (   ) || () () || (    )|| (   ) ||  \  ( || (    \/|  \  ( |   ) (
    //  | |      | |   | || || || || (____)|| |   | ||   \ | || (__    |   \ | |   | |
    //  | |      | |   | || |(_)| ||  _____)| |   | || (\ \) ||  __)   | (\ \) |   | |
    //  | |      | |   | || |   | || (      | |   | || | \   || (      | | \   |   | |
    //  | (____/\| (___) || )   ( || )      | (___) || )  \  || (____/\| )  \  |   | |
    //  (_______/(_______)|/     \||/       (_______)|/    )_)(_______/|/    )_)   )_(

    if (!tableData) {
        return (
            <Spin tip="Loading" size="large">
                <div className="p-12 bg-gray-100 rounded-lg shadow-md" />
            </Spin>
        );
    }

    return (
        <div className="max-h-[100vh] overflow-auto bg-white p-6 rounded-lg shadow-lg">
            <Row gutter={16} className="mb-8">
                <Col span={4}>
                    <Card bordered={false} hoverable className="text-center">
                        <Statistic
                            title={
                                <span className="text-lg font-semibold">
                                    Total TVL
                                </span>
                            }
                            value={totalIdleTvl + totalInvested}
                            precision={2}
                            valueStyle={{ color: '#3f8600', fontSize: '24px' }}
                            prefix={
                                <DollarCircleTwoTone twoToneColor="#52c41a" />
                            }
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card bordered={false} hoverable className="text-center">
                        <Statistic
                            title={
                                <span className="text-lg font-semibold">
                                    Idle TVL
                                </span>
                            }
                            value={totalIdleTvl}
                            precision={2}
                            valueStyle={{ color: '#1890ff', fontSize: '24px' }}
                            prefix={
                                <DollarCircleTwoTone twoToneColor="#1890ff" />
                            }
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card bordered={false} hoverable className="text-center">
                        <Statistic
                            title={
                                <span className="text-lg font-semibold">
                                    Earn TVL
                                </span>
                            }
                            value={totalInvested}
                            precision={2}
                            valueStyle={{ color: '#722ed1', fontSize: '24px' }}
                            prefix={
                                <DollarCircleTwoTone twoToneColor="#722ed1" />
                            }
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card bordered={false} hoverable className="text-center">
                        <Statistic
                            title={
                                <span className="text-lg font-semibold">
                                    Total Realized Gain
                                </span>
                            }
                            value={totalRealizedGain}
                            precision={2}
                            valueStyle={{ color: '#fa8c16', fontSize: '24px' }}
                            prefix={
                                <DollarCircleTwoTone twoToneColor="#fa8c16" />
                            }
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card bordered={false} hoverable className="text-center">
                        <Statistic
                            title={
                                <span className="text-lg font-semibold">
                                    Total Deposited
                                </span>
                            }
                            value={totalDeposited}
                            precision={2}
                            valueStyle={{ color: '#13c2c2', fontSize: '24px' }}
                            prefix={
                                <DollarCircleTwoTone twoToneColor="#13c2c2" />
                            }
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card bordered={false} hoverable className="text-center">
                        <Statistic
                            title={
                                <span className="text-lg font-semibold">
                                    Total Swap Revenue
                                </span>
                            }
                            value={totalSwapRevenue}
                            precision={2}
                            valueStyle={{ color: '#eb2f96', fontSize: '24px' }}
                            prefix={
                                <DollarCircleTwoTone twoToneColor="#eb2f96" />
                            }
                        />
                    </Card>
                </Col>
            </Row>
            <div className="mb-6 flex justify-between items-center bg-gray-50 p-4 rounded-md">
                <Space size="middle" align="start">
                    <div className="flex items-center gap-4">
                        <div>
                            <Typography.Text strong>Sort by:</Typography.Text>
                            <Select
                                defaultValue={'totalPortfolio' as keyof IUser}
                                style={{ width: 180, marginLeft: 8 }}
                                onChange={(value) =>
                                    setSortBy(value as keyof IUser)
                                }
                                options={UserColumns.map(({ key, title }) => ({
                                    value: key,
                                    label: title,
                                }))}
                            />
                        </div>
                        <Dropdown
                            menu={{
                                items: columnGroups.map((group) => ({
                                    key: group.title,
                                    type: 'group',
                                    label: (
                                        <div
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <Checkbox
                                                checked={group.children.every(
                                                    (child) =>
                                                        checkedList.includes(
                                                            child as keyof IUser,
                                                        ),
                                                )}
                                                onChange={(e) => {
                                                    const newCheckedList = e
                                                        .target.checked
                                                        ? [
                                                              ...new Set([
                                                                  ...checkedList,
                                                                  ...group.children,
                                                              ]),
                                                          ]
                                                        : checkedList.filter(
                                                              (item) =>
                                                                  !group.children.includes(
                                                                      item,
                                                                  ),
                                                          );
                                                    setCheckedList(
                                                        newCheckedList as Array<
                                                            keyof IUser
                                                        >,
                                                    );
                                                }}
                                            >
                                                <span className="text-sm font-semibold">
                                                    {group.title}
                                                </span>
                                            </Checkbox>
                                        </div>
                                    ),
                                    children: group.children.map((child) => {
                                        const column = UserColumns.find(
                                            (col) => col.key === child,
                                        );
                                        return {
                                            key: child,
                                            label: (
                                                <div
                                                    onClick={(e) =>
                                                        e.stopPropagation()
                                                    }
                                                >
                                                    <Checkbox
                                                        checked={checkedList.includes(
                                                            child as keyof IUser,
                                                        )}
                                                        onChange={(e) => {
                                                            const newCheckedList =
                                                                e.target.checked
                                                                    ? [
                                                                          ...checkedList,
                                                                          child as keyof IUser,
                                                                      ]
                                                                    : checkedList.filter(
                                                                          (
                                                                              item,
                                                                          ) =>
                                                                              item !==
                                                                              child,
                                                                      );
                                                            setCheckedList(
                                                                newCheckedList,
                                                            );
                                                        }}
                                                    >
                                                        <span className="text-xs">
                                                            {column?.title?.toString()}
                                                        </span>
                                                    </Checkbox>
                                                </div>
                                            ),
                                        };
                                    }),
                                })),
                            }}
                            trigger={['click']}
                            dropdownRender={(menu) => (
                                <div className="max-h-[500px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                                    {menu}
                                </div>
                            )}
                        >
                            <Button icon={<UnorderedListOutlined />}>
                                Columns ({checkedList.length}) <DownOutlined />
                            </Button>
                        </Dropdown>
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        key: 'addFilter',
                                        label: (
                                            <Space size={'large'}>
                                                <Button
                                                    icon={<PlusOutlined />}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.stopPropagation();
                                                        setFilters([
                                                            ...filters,
                                                            {
                                                                column: 'username',
                                                                value: '',
                                                                operator: 'eq',
                                                            },
                                                        ]);
                                                    }}
                                                >
                                                    Add Filter
                                                </Button>
                                                <Switch
                                                    checkedChildren="AND"
                                                    unCheckedChildren="OR"
                                                    defaultChecked
                                                    onChange={(checked) => {
                                                        setFilterCondition(
                                                            checked
                                                                ? 'AND'
                                                                : 'OR',
                                                        );
                                                    }}
                                                    onClick={(_checked, e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </Space>
                                        ),
                                    },
                                    ...filters.map((filter, index) => ({
                                        key: `filter-${index}`,
                                        label: (
                                            <Space
                                                align="center"
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                            >
                                                <Select
                                                    style={{ width: 120 }}
                                                    value={filter.column}
                                                    onChange={(value) => {
                                                        const newFilters = [
                                                            ...filters,
                                                        ];
                                                        newFilters[
                                                            index
                                                        ].column =
                                                            value as keyof IUser;
                                                        setFilters(newFilters);
                                                    }}
                                                    options={UserColumns.map(
                                                        ({ key, title }) => ({
                                                            value: key,
                                                            label: title,
                                                        }),
                                                    )}
                                                />
                                                <Select
                                                    style={{ width: 80 }}
                                                    value={filter.operator}
                                                    onChange={(value) => {
                                                        const newFilters = [
                                                            ...filters,
                                                        ];
                                                        newFilters[
                                                            index
                                                        ].operator = value as
                                                            | 'eq'
                                                            | 'gt'
                                                            | 'lt'
                                                            | 'neq';
                                                        setFilters(newFilters);
                                                    }}
                                                    options={[
                                                        {
                                                            value: 'eq',
                                                            label: '=',
                                                        },
                                                        {
                                                            value: 'gt',
                                                            label: '>',
                                                        },
                                                        {
                                                            value: 'lt',
                                                            label: '<',
                                                        },
                                                        {
                                                            value: 'neq',
                                                            label: '!=',
                                                        },
                                                    ]}
                                                />
                                                <Input
                                                    style={{ width: 120 }}
                                                    value={filter.value}
                                                    onChange={(e) => {
                                                        const newFilters = [
                                                            ...filters,
                                                        ];
                                                        newFilters[
                                                            index
                                                        ].value =
                                                            e.target.value;
                                                        setFilters(newFilters);
                                                    }}
                                                />
                                                <Button
                                                    type="text"
                                                    danger
                                                    icon={<DeleteOutlined />}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const newFilters =
                                                            filters.filter(
                                                                (_, i) =>
                                                                    i !== index,
                                                            );
                                                        setFilters(newFilters);
                                                    }}
                                                />
                                            </Space>
                                        ),
                                    })),
                                ],
                            }}
                            trigger={['click']}
                        >
                            <Button icon={<FilterOutlined />}>
                                Filters ({filters.length}) <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                    <Button
                        ref={applyButtonRef}
                        type="primary"
                        icon={<SearchOutlined />}
                        onClick={() =>
                            setConfig({
                                sortBy: sortBy,
                                filters: filters,
                                filterCondition: filterCondition,
                            })
                        }
                    >
                        Apply
                    </Button>
                </Space>
                <Button type="link" icon={<DownloadOutlined />}>
                    <CSVLink data={getExportableData(tableData)}>
                        Export
                    </CSVLink>
                </Button>
            </div>
            <div className="mb-4">
                <Typography.Text strong className="text-lg">
                    Total Rows: {totalRows}
                </Typography.Text>
            </div>
            <Table
                pagination={{
                    pageSize: PAGE_SIZE,
                    hideOnSinglePage: true,
                }}
                dataSource={tableData}
                expandable={
                    balances && balancesUSD
                        ? {
                              expandedRowRender: (record) => (
                                  <div className="bg-gray-50 p-4 rounded-md">
                                      {Object.entries(
                                          balancesUSD[record.key] ?? {},
                                      ).map(
                                          ([token, balance]) =>
                                              balance > 0.1 && (
                                                  <p
                                                      key={token + record.key}
                                                      className="mb-2"
                                                  >
                                                      <span className="font-semibold">
                                                          {addressToToken[token]
                                                              ?.symbol ?? token}
                                                          :
                                                      </span>{' '}
                                                      {toDollar(balance)} (
                                                      {balances[record.key][
                                                          token
                                                      ].toFixed(
                                                          addressToToken[token]
                                                              ?.precision ?? 2,
                                                      )}
                                                      )
                                                  </p>
                                              ),
                                      )}
                                  </div>
                              ),
                          }
                        : undefined
                }
                columns={columns}
                className="shadow-sm"
            />
        </div>
    );
};
