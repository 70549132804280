/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ResponsivePie } from '@nivo/pie';
import type { $MixedElement } from 'types';
import type { PieChartData, PieChartProps } from 'utils';

export const PieChart = ({
    data,
    props,
}: {
    data: Array<PieChartData>;
    props: PieChartProps;
}): $MixedElement => (
    <ResponsivePie
        data={data}
        sortByValue={true}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        valueFormat={props.valueFormat}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
        }}
        arcLinkLabelsSkipAngle={5}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]],
        }}
    />
);
