/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { readContracts } from '@wagmi/core';
import { queryKeys } from 'api/queryKeys';
import type { CustomQueryResult } from 'api/types';
import { erc20Abi } from 'viem';
import { config } from 'wagmi-config';

erc20Abi;

const ABI = [
    {
        inputs: [
            {
                internalType: 'address',
                name: 'validator',
                type: 'address',
            },
        ],
        name: 'r1IsValidator',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
] as const;

const VALIDATOR_ADDRESS = '0x644cb88c255e6dca440d5dbec6c60cb80cfc9746';

export const useIsUpgradedQuery = (
    addresses: Array<string>,
): CustomQueryResult<Record<string, boolean | null>> => {
    const { data, refetch, ...rest } = useQuery({
        queryKey: [queryKeys.IS_UPGRADED],
        queryFn: async () => {
            if (addresses.length < 15000) {
                return {};
            }

            const batchSize = 1000;
            const result: Record<string, boolean | null> = {};

            for (let i = 0; i < addresses.length; i += batchSize) {
                const batch = addresses.slice(i, i + batchSize);
                const batchData = await readContracts(config, {
                    contracts: batch.map((address) => ({
                        address: address as `0x${string}`,
                        abi: ABI,
                        functionName: 'r1IsValidator' as const,
                        args: [VALIDATOR_ADDRESS],
                    })),
                    allowFailure: true,
                });

                batch.forEach((address, index) => {
                    const batchResult = batchData?.[index] ?? null;
                    result[address] =
                        batchResult?.status === 'success'
                            ? batchResult.result
                            : null;
                });
            }

            return result;
        },
        gcTime: Infinity,
        staleTime: Infinity,
    });

    return { data: data ?? {}, refetch: refetch, ...rest };
};
