/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ResponsiveBar } from '@nivo/bar';
import type { $MixedElement } from 'types';
import type { BarChartData, BarChartProps } from 'utils';

export const BarChart = <K extends string>({
    data,
    props,
}: {
    data: Array<BarChartData<K>>;
    props: BarChartProps<K>;
}): $MixedElement => (
    <ResponsiveBar
        data={data}
        keys={props.keys}
        indexBy={props.indexBy}
        margin={{ top: 30, right: 130, bottom: 80, left: 60 }}
        padding={0.3}
        valueScale={props.logScale || { type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'set3' }}
        valueFormat={props.valueFormat}
        borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
        }}
        theme={{
            axis: {
                ticks: {
                    text: {
                        fontSize: 8,
                    },
                },
            },
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -25,
            legend: props.axisBottomLegend,
            legendPosition: 'middle',
            legendOffset: 55,
            truncateTickAt: 0,
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: props.axisLeftLegend,
            legendPosition: 'middle',
            legendOffset: -50,
            truncateTickAt: 0,
        }}
        labelSkipWidth={36}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
        }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1,
                        },
                    },
                ],
            },
        ]}
        role="application"
        groupMode={props.groupMode}
        enableTotals={props.enableTotals}
        barAriaLabel={(e) => 'amount: ' + e.indexValue}
    />
);
