/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Spin } from 'antd';
import { useMemo } from 'react';
import type { $MixedElement } from 'types';
import {
    ETH_ADDRESS,
    GRAPH_ETH_ADDRESS,
    type PieChartData,
    type UserBalances,
    addressToToken,
    formatUnits,
} from 'utils';

import { PieChart } from '../charts/PieChart';
import type { StatsQuery } from '.graphclient';

export const PieCharts = ({
    data,
    isLoading,
    tokenPrices,
    balancesUSD,
}: {
    data: StatsQuery;
    isLoading: boolean;
    tokenPrices: Record<string, number> | null;
    balancesUSD: UserBalances | null;
}): $MixedElement => {
    const backupChartData = useMemo(() => {
        const backedUp = data.total?.backedUp ?? 0;
        const deployed = data.total?.deployedAccounts ?? 0;
        const total = data.total?.createdAccounts ?? 0;

        return [
            {
                id: 'Have Backup',
                label: 'Have Backup',
                value: backedUp,
            },
            {
                id: 'Not Deployed',
                label: 'Not Deployed',
                value: total - deployed,
            },
            {
                id: 'No Backup',
                label: 'No Backup',
                value: deployed - backedUp,
            },
        ];
    }, [data]);

    const balanceChartData = useMemo(() => {
        if (!balancesUSD || !tokenPrices || !data) return [];

        const balanceChartData: Array<PieChartData> = [];
        const totalValuePerToken = Object.values(balancesUSD).reduce(
            (acc, balance) => {
                Object.entries(balance).forEach(([tokenAddress, amount]) => {
                    acc[tokenAddress] = (acc[tokenAddress] ?? 0) + amount;
                });

                return acc;
            },
            {},
        );

        const totalValuePerEarn = data.months.reduce(
            (acc: Record<string, number>, month) => {
                month.investFlow.forEach((flow) => {
                    let tokenAddress = flow.erc20 as string;
                    if (tokenAddress === GRAPH_ETH_ADDRESS)
                        tokenAddress = ETH_ADDRESS;
                    const token = addressToToken[tokenAddress];
                    if (!token) return;
                    const name = token.symbol;
                    const decimal = token.decimals ?? 18;
                    const protocol = flow.protocol as string;
                    const id = `${name}-${protocol}`;
                    const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                    const inflow = formatUnits(flow.amountIn, decimal);
                    const inflowUsdc = inflow * tokenPrice;
                    const outflow = formatUnits(flow.amountOut, decimal);
                    const outflowUsdc = outflow * tokenPrice;
                    const amount = inflowUsdc - outflowUsdc;
                    acc[id] = (acc[id] ?? 0) + amount;
                });
                return acc;
            },
            {} as Record<string, number>,
        );

        const others = {
            id: 'Others',
            label: 'Others',
            value: 0,
        };

        Object.entries(totalValuePerToken).forEach(
            ([tokenAddress, totalValue]) => {
                const token = addressToToken[tokenAddress];
                if (!token) return;

                if (totalValue < 1) return;

                if (totalValue < 500) {
                    others.value += totalValue;
                    return;
                }

                balanceChartData.push({
                    id: token.symbol,
                    label: token.symbol,
                    value: totalValue,
                });
            },
        );

        if (others.value > 0) balanceChartData.push(others);

        Object.entries(totalValuePerEarn).forEach(([token, totalValue]) => {
            if (totalValue < 1) return;

            balanceChartData.push({
                id: token,
                label: token,
                value: totalValue,
            });
        });

        return balanceChartData;
    }, [balancesUSD, data, tokenPrices]);

    if (isLoading) {
        return (
            <Spin tip="Loading" size="small">
                <div className="p-12 bg-gray-100 rounded-sm" />
            </Spin>
        );
    }
    return (
        <div className="w-[100%] min-h-[80vh]">
            <PieChart
                data={balanceChartData}
                props={{ valueFormat: ' >-$.0f' }}
            />
            <PieChart
                data={backupChartData}
                props={{ valueFormat: undefined }}
            />
        </div>
    );
};
